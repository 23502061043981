<template>
  <!--
    The view for the Installation map sidebar report chart.
    Used to display information about the installations media (skimovie, phototrap, etc.).
  -->
  <div class="map-sideboar-report">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <template v-if="spotIds.length > 0">
        <div
          id="nav-tab"
          class="nav nav-tabs"
          role="tablist"
        >
          <a
            v-for="(key, index) in spotIds"
            :id="`spoT${ index }-tab`"
            :key="`Report-tab-listItem-${ index }`"
            :class="['nav-item nav-link', {'active': index == selectedTab}]"
            data-toggle="tab"
            role="tab"
            :aria-controls="`spoT${ index }`"
            :aria-selected="index === selectedTab"
            @click="changeTab(index)"
          >Spot: {{ key }}</a>
        </div>
        <div
          id="nav-tabContent"
          class="tab-content"
        >
          <div
            v-for="(spotId, index) in spotIds"
            :id="`spoT${ index }`"
            :key="`Report-spot-listItem-${ index }`"
            :class="['reports-pane tab-pane fade show', {'active': index == selectedTab}]"
            role="tabpanel"
            :aria-labelledby="`spoT${ index }-tab`"
          >
            <div
              v-if="showMediaReportChart"
              class="card"
            >
              <MediaReportChartMapSidebar
                :installation-id="installationId"
                :spot-id="spotId"
              />
            </div>
          </div>
        </div>
      </template>
      <template
        v-else-if="isPhotopoint"
      >
        <div
          class="card"
        >
          <MediaReportChartMapSidebar
            :installation-id="installationId"
            :spot-id="-999"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailReports",
  components: {
    MediaReportChartMapSidebar: () => import('@/components/Media/MediaReport/MediaReportChartMapSidebar.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      installationObject: null,
      loading: true,
      spotIds: [],
      selectedTab: null
    }
  },
  metaInfo () {
    return {
      title: this.$t('installationDetailReportsView.reports')
    }
  },
  computed: {
    isPhotopoint () {
      if (!this.installationObject.installationType) {
        return false;
      }
      let type = this.installationObject.installationType.trim().toLowerCase();
      let allowedTypes = [ 'photopoint' ];
      return allowedTypes.includes(type);
    },
    showMediaReportChart () {
      if (!this.installationObject.installationType) {
        return false;
      }
      let type = this.installationObject.installationType.trim().toLowerCase();
      let allowedTypes = [ 'photostart', 'skimovie', 'biketrack', 'speedcheck', 'phototrap' ];
      return allowedTypes.includes(type);
    }
  },
  created () {
    this.selectedTab = 0;
    this.getInstallation();
  },
  methods: {
    changeTab (e) {
      this.selectedTab = e;
    },
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
          if(!this.isPhotopoint) {
            this.getInstallationSpotIds();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
      getInstallationSpotIds () {
      this.loading = true;
      this.axios.get('/Media/GetSpotIds/' + this.installationId)
        .then((response) => {
          this.spotIds = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.reports-pane:not(.active) {
  display: block;
  height: 0;
  overflow-y: hidden;
}
</style>